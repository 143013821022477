<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <filters ref="filters" />
      <b-card no-body>
        <b-table class="position-relative" :hover="true" :items="records" responsive :fields="fields" show-empty
          empty-text="No existen" @sort-changed="sortChanged" no-border-collapse>
          <!-- Column: Actions -->
          <template #cell(type)="data">
            <div class="description-class">

              <span>{{ getType(data.item) }}</span>
            </div>
          </template>
          <template #cell(area)="data">
            <div class="description-class">

              <span>{{ getArea(data.item) }}</span>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button size="sm" class="" @click.prevent="edit(data.item)"
              v-b-tooltip.noninteractive.hover.top="'Editar'" variant="flat-success" v-if="validateOptions">
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button size="sm" class="" v-b-tooltip.noninteractive.hover.top="'Eliminar'"
              @click="deleteAction(data.item)" variant="flat-danger" v-if="validateOptions">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group label-cols="4" label-cols-md="4" label-size="md" label="Entradas" label-for="input-md">
                <b-form-select v-model="showEntrie" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                  :options="entries" @change="changeSizePage" />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalElements" :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="cambioPagina" pills>
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import filters from './filters.vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AreasService from '@/services/AreasService'
import UserService from '@/services/UserService'
import RoleUserService from '@/services/RoleUserService'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ProjectsService from '@/services/ProjectsService'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      userId: null,
      showLoading: false,
      modalOpen: false,
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'type', label: 'Tipo', sortable: true },
        { key: 'area', label: 'Area', sortable: true },
        { key: 'dni', label: 'DNI', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'actions', label: 'Acciones' }
      ],
      form: {
        name: '',
        email: '',
        area_name: '',
        project_filter_id: null,
        dni: '',
        photo: null,
        projects: [],
        area_owner_id: null,
        tipo: '',
        telefono: '',
        userType: null,
      },
      form_permissons: {
        is_restrictions: null,
        role_restriccion: null,
        frecuency: 0,
        type: null,
        area_owner_id: null,
        projects_restriccion: null,
        is_lessons: null,
        role_lesson: null,
        ambit: null,
        area_name: null,
        project_lesson: null,
        isJob: false,
        phone: null,
        is_security: false,
        area_project_name: ''
      },
      ambits: [
        "Oficina",
        "Proyecto"
      ],
      role_restriccions: [
        "Usuario",
        "Planner",
        "Administrador"
      ],
      role_restriccionsPlanner: [
        "Usuario",
        "Planner",
      ],
      role_lessons: [
        "Aprobador",
        "Revisor",
        "Administrador"
      ],
      records: [],
      projects: [],
      areas: [
        {
          text: 'Comunicaciones',
          value: 'comunicaciones'
        },
        {
          text: 'Tecnologías de las Información',
          value: 'tecnologias-de-las-informacion'
        },
        {
          text: 'Selección y Desarrollo',
          value: 'seleccion-y-desarrollo'
        },
        {
          text: 'Nómina y Relaciones Laborales',
          value: 'nomina-y-relaciones-laborales'
        },
        {
          text: 'Responsabilidad Social y Bienestar',
          value: 'responsabilidad-social-y-bienestar'
        },
        {
          text: 'Archivo',
          value: 'archivo'
        },
        {
          text: 'Impuestos y Cuentas por Pagar',
          value: 'impuestos-y-cuentas-por-pagar'
        },
        {
          text: 'Finanzas y Seguros',
          value: 'finanzas-y-seguros'
        },
        {
          text: 'Costos y Control de Gestión',
          value: 'costos-y-control-de-gestion'
        },
        {
          text: 'Contabilidad',
          value: 'contabilidad'
        },
        {
          text: 'Logística',
          value: 'logistica'
        },
        {
          text: 'Almacén Central y Transporte',
          value: 'almacen-central-y-transporte'
        },
        {
          text: 'Negocios',
          value: 'negocios'
        },
        {
          text: 'Propuestas',
          value: 'propuestas'
        },
        {
          text: 'SSOMA',
          value: 'ssomac'
        },
        {
          text: 'Equipos',
          value: 'equipos'
        },
        {
          text: 'Contratos',
          value: 'contratos'
        },
        {
          text: 'Legal',
          value: 'legal'
        },
        {
          text: 'Planeamiento',
          value: 'planeamiento'
        }
      ],
      areas_owner: [],
      showSecondSelect: false,
      showAmbitSelect: false,
      showProjectSelect: false,
      showAreaSelect: false,
      project_id: JSON.parse(localStorage.getItem('project_id')),
      user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
      documentSelect: [],
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc',
      validateOptions:true
    }
  },
  computed: {
    roleRestrictionSelected() {
      return this.form_permissons.role_restriccion;
    },
    roleLessonSelected() {
      return this.form_permissons.role_lesson;
    },
    ambitSelected() {
      return this.form_permissons.ambit;
    },
  },
  watch: {

    roleRestrictionSelected(newValue) {
      // Si elige "Usuario" o "Planner", mostrar el segundo v-select y otros elementos.
      if (newValue === "Usuario" || newValue === "Planner") {
        this.showSecondSelect = true;
      } else {
        // Si selecciona "Administrador" o algo más, mantener todo oculto.
        this.showSecondSelect = false;
        // También puedes restablecer el valor de otros campos aquí si es necesario.
      }
    },

    roleLessonSelected(newValue) {
      // Habilitar o deshabilitar el v-select de Ámbito según la selección en el primer v-select.
      this.showAmbitSelect = newValue === 'Aprobador' || newValue === 'Revisor';

      // Restablecer valores cuando se deshabilita el v-select de Ámbito.
      if (!this.showAmbitSelect) {
        this.form_permissons.ambit = null;
      }
    },
    ambitSelected(newValue) {
      // Habilitar o deshabilitar el v-select de Proyecto o Área según la selección en el v-select de Ámbito.
      this.showProjectSelect = newValue === 'Proyecto';
      this.showAreaSelect = newValue === 'Oficina';

      // Restablecer valores cuando se deshabilita el v-select de Proyecto o Área.
      if (!this.showProjectSelect) {
        this.form_permissons.project_lesson = null;
      }
      if (!this.showAreaSelect) {
        this.form_permissons.area_name = null;
      }
    },
  },
  components: {
    vSelect,
    filters,
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    this.getData()
    this.getProjects()

  },
  methods: {
    openModal(itemId) {
      this.userId = itemId;
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },


    async getProjects() {

      const url = `?limit=100&`
      this.showLoading = true
      const respProyectos = await ProjectsService.getProyectosAll(url, this.$store)
      console.log({ respProyectos })
      if (respProyectos.status) {
        this.projects = respProyectos.data.rows
        console.log({ projectsss: this.projects })
      }
    },
    async getData() {
      console.log("FILTERS", this.arrayFilters)
      console.log('validation',this.validateOptions)
      this.showLoading = true
      const url =
        `?limit=${this.showEntrie}&page=${this.currentPage}&filter=` +
        JSON.stringify(this.arrayFilters)

      const resp = await UserService.getUsers(url, this.$store)
      console.log("USUARIOS", resp)

      if (resp.status) {
        this.records = resp.data.rows
        this.totalElements = resp.data.responseFilter.total_rows
      }
      this.showLoading = false
    },
    async editRoleUser(item) {
      console.log("ITEM ID EDIT", { edit: item })

      //Reemplazar por servicio RoleUser

      const resp = await RoleUserService.getUserRole(item.id, this.$store)
      console.log("RESP DEL EDIT USER ROLE", { resp })
      if (resp.status) {

        this.form_permissons.is_restrictions = resp.data.is_restriction
        if (resp.data.role_restriction === 'user') {
          this.form_permissons.role_restriccion = "Usuario"
        }
        if (resp.data.role_restriction === 'planner') {
          this.form_permissons.role_restriccion = "Planner"
        }
        if (resp.data.role_restriction === 'admin') {
          this.form_permissons.role_restriccion = "Administrador"
        }
        this.form_permissons.phone = resp.data.phone
        this.form_permissons.type = resp.data.type
        this.form_permissons.area_owner_id = resp.data.area_id
        this.form_permissons.projects_restriccion = resp.data.project_id
        this.form_permissons.is_lessons = resp.data.is_lessons
        if (resp.data.role_lesson === 'user-lesson') {
          this.form_permissons.role_lesson = "Aprobador"
        }
        if (resp.data.role_lesson === 'reviewer-lesson') {
          this.form_permissons.role_lesson = "Revisor"
        }
        if (resp.data.role_lesson === 'admin-lesson') {
          this.form_permissons.role_lesson = "Administrador"
        }
        this.form_permissons.ambit = resp.data.ambit
        this.form_permissons.area_name = resp.data.area_name
        this.form_permissons.project_lesson = resp.data.project_lection
        this.form_permissons.isJob = resp.data.is_job
        this.form_permissons.frecuency = resp.data.frecuency

      }
    },
    async edit(item) {

      const respEdit = await UserService.getUser(item.id, this.$store)
      const resp = await RoleUserService.getUserRole(item.id, this.$store)
      console.log("RESP EDIT User", respEdit)
      console.log("RESP EDIT User Role", resp)
      if (respEdit.status && resp.status) {
        console.log({ resp })
        this.form.id = item.id
        this.form.name = item.name
        this.form.email = item.email
        this.form.dni = item.dni
        this.form.photo = respEdit.data.url

        if (item.project_user.length > 0) {
          const filids = item.project_user.map((e) => {
            return e.project.id
          })
          this.form.projects = filids

        } else {
          this.form.projects = []
        }

        this.form.is_restrictions = resp.data.is_restriction
        this.form.role_restriccion = resp.data.role_restriction
        this.form.phone = resp.data.phone
        this.form.type = respEdit.data.type
        console.log("ITEM A MOSTRAR", item)
        console.log("USER", item.role_user[0].role_restriction)
        if (item.role_user[0].role_restriction == 'user-collection') {
          this.form.area_owner_id = respEdit.data.template_area.id
          console.log("AREA OWNER ID", this.form.area_owner_id)
        } else {
          this.form.area_owner_id = respEdit.data.area_id
          console.log("NONONO")
        }
        this.form.project_filter_id = respEdit.data.project_general_id
        this.form.projects_restriccion = resp.data.project_id ? resp.data.project_id[0] : resp.data.project_id
        this.form.is_lessons = resp.data.is_lessons
        this.form.role_lesson = resp.data.role_lesson
        this.form.ambit = resp.data.ambit
        this.form.area_name = resp.data.area_name
        this.form.project_lesson = resp.data.project_lection
        this.form.projects_id = item.project_user.map((e) => {
          return e.project
        })
        this.form.isJob = resp.data.is_job
        this.form.frecuency = resp.data.frecuency
        this.form.is_security = resp.data.is_security
        this.form.area_project_name = resp.data.area_project_name
        console.log('this items seend', this.form)
        this.$refs.filters.isAdd = true
        this.$refs.filters.$children[0].setData(this.form)
      }
    },
    async deleteAction(data) {
      console.log("DATA", data)
      let valResp = await UserService.validateDeleteUser(data.id, this.$store);
      console.log({ valResp })
      if (!valResp.data.canDelete) {
        this.$swal({
          title: 'Error',
          text: valResp.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false
        })
      } else {
        this.$swal({
          title: '¿Desea eliminar este usuario?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminalo',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            console.log("DATA", data)
            console.log("DATA", data.project_user[0].project_id)
            this.deleteData(data.id, data.project_user[0].project_id, data.role_user[0].role_restriction)
          }
        })
      }
    },
    async deleteData(id, project_id, role) {
      let resp
      if (role == 'user-collection') {
        resp = await UserService.deleteUserMultiple(id, this.$store)
        console.log("ES MULTIPLER", resp)
      } else {
        resp = await UserService.deleteUser(id, project_id, this.$store)
        console.log("NO ES MULTIPLER")

      }
      if (resp.status) {
        this.currentPage = 1
        this.$swal({
          icon: 'success',
          title: 'Eliminado!',
          text: 'El usuario ha sido eliminado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
        this.getData()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar el dueño seleccionado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    getType(item) {
      let type = ""
      if (item.owner.length > 0) {
        type = "Proyecto"
      }
      if (item.co_owner.length > 0) {
        switch (item.co_owner[0].area.type) {
          case '002':
            type = "Subcontratista / Servicios"
            break;
          case '003':
            type = "Cliente"
            break;
          default:
            type = "Oficina Principal"
            break;
        }
      }
      return type
    },
    getArea(item) {
      let area = ""
      if (item.owner.length > 0) {
        area = item.owner[0].area.description
      }
      if (item.co_owner.length > 0) {
        area = item.co_owner[0].area.description
      }
      return area
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.checkboxOption {
  display: flex;
  gap: 1rem;
}

.checkboxOption input {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50px;
}

.checkboxOption label {
  font-size: 1.5rem;
}

.select-obra {
  width: 100%;
}

.newinput {
  width: 10000px;
}
</style>
